export default [
  {
    header: '客户管理',
  },
  {
    title: '客户公司列表',
    icon: 'HomeIcon',
    route: 'crm-companies-system',
  },
  {
    title: '客户联系人列表',
    icon: 'UserIcon',
    route: 'crm-contacts-system',
  },

]
