export default [
  {
    title: '首页',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-ecommerce',

/*     children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ], */
  },
]