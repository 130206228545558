export default [
    {
      header: '第三方App',
    },
    {
      title: '微信联系人列表',
      icon: 'UserIcon',
      route: 'third-party-wechat',
    },
  ]
  