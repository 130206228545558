export default [
  {
    header: '业务协同',
  },
  {
    title: '项目管理',
    icon: 'ListIcon',
    route: 'collab-pm',
  },
  {
    title: '审批流',
    icon: 'PenToolIcon',
    route: 'collab-approvals',
  },
  {
    title: '客户回款管理',
    icon: 'DollarSignIcon',
    route: 'collab-statements-manage',
  },
  {
    title: '供应商结算管理',
    icon: 'DollarSignIcon',
    route: 'collab-vendor-statements-manage',
  }
]
