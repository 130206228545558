export default [
  {
    header: '供应商管理',
  },
  {
    title: '供应商公司列表',
    icon: 'HomeIcon',
    route: 'vendors-companies-system',
  },
  {
    title: '供应商联系人列表',
    icon: 'UserIcon',
    route: 'vendors-contacts-system',
  },
  {
    title: '供应商申请列表',
    icon: 'FileTextIcon',
    route: 'vendors-applications',
  },
  {
    title: '众包用户列表',
    icon: 'GlobeIcon',
    route: 'vendors-individuals',
  }
]
