export default [
  {
    header: '拓客管理',
  },
  {
    title: '目标公司列表',
    icon: 'HomeIcon',
    route: 'potential-target-companies',
  },
  {
    title: '目标联系人列表',
    icon: 'UserIcon',
    route: 'potential-target-contacts',
  },
  {
    title: '线索联系人列表',
    icon: 'UserIcon',
    route: 'potential-leads-contacts',
  },

]
