export default [
  {
    header: '企业管理',
  },
  {
    title: '用户管理',
    route: 'manage-users',
    icon: 'UserIcon',
  },
]
